
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import { Head, Notes } from 'mdx-deck'
import { theme } from './theme'
export const themes = [theme];
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  themes
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Head mdxType="Head">
  <title>Presentation Title</title>
    </Head>
    <h1>{`Hello`}</h1>
    <hr></hr>
    <h2>{`Edit this file`}</h2>
    <p>{`To create your presentation`}</p>
    <Notes mdxType="Notes">
Create speaker notes with the Notes component
    </Notes>
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "https://github.com/jxnblk/mdx-deck"
      }}>{`https://github.com/jxnblk/mdx-deck`}</a></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;